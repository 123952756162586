import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService, UfControl, UfControlArray, UfControlGroup, ValidatorFunctions } from '@unifii/library/common';
import { UfError, hasLengthAtLeast, isString } from '@unifii/sdk';
import { isAfter, parseISO } from 'date-fns';
import { Subscription } from 'rxjs';

import { App, UcApps } from 'client';
import { useDefaultErrorMessage } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { EditData } from 'components/common/edit-data';
import { SaveAndClose, SaveOption, SaveOptionType } from 'components/common/save-options/save-options.component';
import { ContextService } from 'services/context.service';
import { DialogsService } from 'services/dialogs.service';

import { AppDetailsComponent } from './app-details.component';

@Component({
    selector: 'uc-app-settings',
    templateUrl: 'app-settings.html',
})
export class AppSettingsComponent implements EditData, OnDestroy, OnInit {

    protected readonly saveOptions = [SaveAndClose];
    protected readonly arePushNotificationsEnabled = inject(ContextService).tenantSettings?.arePushNotificationsEnabled ?? false;
    protected error: UfError | undefined;
    protected app: App;
    protected form = new UfControlGroup({
        id: new UfControl(ValidatorFunctions.required('Id is required.')),
        name: new UfControl(ValidatorFunctions.required('Name is required.')),
        appSecretControl: new UfControl(ValidatorFunctions.minLength(12, 'Secret must be at least 12 characters.')),
        corsOrigins: new UfControlArray([]),
    });
    protected parent = inject(AppDetailsComponent);

    private editSecret = false;
    private subscriptions = new Subscription();
    private ucApps = inject(UcApps);
    private toastService = inject(ToastService);
    private dialogs = inject(DialogsService);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private builderHeaderService = inject(BuilderHeaderService);

    set edited(v: boolean) {
        this.builderHeaderService.config.edited = v;
    }

    get edited(): boolean {
        return this.builderHeaderService.config.edited ?? false;
    }

    protected get isNew(): boolean {
        return this.id === 'new';
    }

    protected get isAppleCertExpired(): boolean {
        return this.app.appleCertNotAfter != null && isAfter(new Date(), parseISO(this.app.appleCertNotAfter));
    }

    protected get showAppleCertDetails(): boolean {
        return this.app.appleCertName != null && !this.uploadedAppleCert;
    }

    protected get showAppleCertPreview(): boolean {
        return !this.app.appleCertName && this.uploadedAppleCert;
    }

    protected get uploadedAppleCert(): boolean {
        return this.app.appleCert != null && !this.app.appleCertName;
    }

    private get id(): string {
        return this.route.snapshot.params.id as string;
    }

    async ngOnInit() {
        try {
            if (this.isNew) {
                this.app = {
                    id: null as any,
                    name: null as any,
                    corsOrigins: [],
                };
            } else {
                this.app = await this.ucApps.getDetail(this.id);
            }

            const corsOriginsControl = this.form.get('corsOrigins') as UfControlArray | null;

            if (corsOriginsControl && this.app.corsOrigins) {
                this.app.corsOrigins.forEach(() => corsOriginsControl.push(this.getCorsControl()));
            }

            this.init();
        } catch (e) {
            this.error = useDefaultErrorMessage(e);
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.parent.buildHeaderConfig();
    }

    protected updateGoogleKey(files: File[]) {

        if (!hasLengthAtLeast(files, 1)) {
            return;
        }

        const reader = new FileReader();

        reader.addEventListener('load', () => {

            this.app.googleKey = isString(reader.result) ? reader.result.split(',')[1]: undefined;

            this.form.markAsDirty();
            this.edited = false;

        }, { capture: false, once: true });

        reader.readAsDataURL(files[0]);
    }

    protected deleteGoogleKey() {
        delete this.app.googleKey;
    }

    protected downloadGoogleKey() {
        const link = document.createElement('a');

        link.setAttribute('href', 'data:text/csv;charset=utf-8;base64,' + this.app.googleKey);
        link.setAttribute('download', this.app.id + '.p12');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    protected updateAppleCertificate(files: File[]) {

        if (!hasLengthAtLeast(files, 1)) {
            return;
        }

        const reader = new FileReader();

        reader.addEventListener('load', () => {

            this.app.appleCert = isString(reader.result) ? reader.result.split(',')[1]: undefined;

            delete this.app.appleCertName;
            delete this.app.appleCertNotAfter;
            delete this.app.appleIsSandbox;

            this.form.markAsDirty();
            this.edited = false;

        }, { capture: false, once: true });

        reader.readAsDataURL(files[0]);
    }

    protected deleteAppleCert() {
        delete this.app.appleCert;
        delete this.app.appleCertName;
        delete this.app.appleCertNotAfter;
        delete this.app.appleIsSandbox;
    }

    protected downloadAppleCertificate() {
        const link = document.createElement('a');

        link.setAttribute('href', 'data:text/csv;charset=utf-8;base64,' + this.app.appleCert);
        link.setAttribute('download', this.app.id + '.p12');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    protected addCorsOrigin() {
        (this.form.controls.corsOrigins as UfControlArray).push(this.getCorsControl());
    }

    protected async deleteCorsOrigin(i: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }
        this.app.corsOrigins?.splice(i, 1);
        (this.form.controls.corsOrigins as UfControlArray).removeAt(i);
    }

    protected save(saveOption?: SaveOption) {

        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        if (!this.editSecret && !this.app.secret) {
            delete this.app.secret;
        }

        if (this.app.corsOrigins) {
            this.app.corsOrigins = this.app.corsOrigins.map((url) => this.clearOriginUrl(url));
        }

        this.ucApps.save(this.app).then((result) => {

            this.edited = false;
            this.editSecret = false;
            this.form.markAsPristine();

            this.toastService.success('App saved');
            this.app = result;

            if (this.isNew) {
                this.ucApps.appAdded.next(this.app);
            } else {
                this.ucApps.appModified.next(this.app);
            }

            if (saveOption?.id === SaveOptionType.Close) {
                void this.router.navigate(['..'], { relativeTo: this.route });

                return;
            }

            this.init();
        }, (err) => {
            this.toastService.error(err.message || 'Save failed');
        });

    }

    protected changeSecret(secret: string) {
        this.app.secret = secret;
        this.editSecret = true;
    }

    private init() {

        this.editSecret = !this.app.secret;

        this.buildHeaderConfig();
    }

    private getCorsControl(): UfControl {
        return new UfControl(ValidatorFunctions.required('Origin is required'));
    }

    private clearOriginUrl(url: string): string {
        return url.trim().replace(/\/$/, '');
    }

    private buildHeaderConfig() {
        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => this.save(saveOption)));
        this.builderHeaderService.updateConfig({
            hideSaveButton: false,
            lastModifiedAt: this.app.lastModifiedAt,
            lastModifiedBy: this.app.lastModifiedBy,
            saveOptions: this.saveOptions,
            cancelRoute: ['..'],
        });
        this.subscriptions.add(this.form.statusChanges.subscribe(() => { this.builderHeaderService.config.edited = !this.form.pristine; }));
    }

}
