import { Routes } from '@angular/router';
import { RunSeriallyGuard } from '@unifii/library/common';
import { CompoundType } from '@unifii/sdk';
import { userProvisioningCacheGuard } from '@unifii/user-provisioning';

import { canDeactivateEditDataComponent } from 'components/common/edit-data';
import { canDeactivateBuilder } from 'components/compound-builder/builder-basic';
import { PermissionsComponent, resourceGuard } from 'components/permissions';
import { UrlSegments } from 'constant';
import { canActivateAccount } from 'guards/account-guard';
import { anonymousGuard } from 'guards/anonymous-guard';
import { mfaGuard } from 'guards/mfa-guard';
import { passwordChangeGuard } from 'guards/password-change-guard';
import { canActivateTenant } from 'guards/tenant-guard';
import { ProjectComponent } from 'navigation/project.component';
import { TenantComponent } from 'navigation/tenant.component';
import { ClaimDetailComponent, ClaimTableComponent } from 'pages';
import { APIKeyDetailsComponent } from 'pages/api-keys/api-key-details.component';
import { APIKeysComponent } from 'pages/api-keys/api-keys.component';
import { AppDetailsComponent } from 'pages/apps/app-details.component';
import { AppDevicesComponent } from 'pages/apps/app-devices.component';
import { AppSendNotificationComponent } from 'pages/apps/app-send-notification.component';
import { AppSettingsComponent } from 'pages/apps/app-settings.component';
import { AppsComponent } from 'pages/apps/apps.component';
import { DeviceDetailComponent } from 'pages/apps/device-detail.component';
import { AssetsComponent } from 'pages/assets/assets.component';
import { AuditLogComponent } from 'pages/audit-log/audit-log.component';
import { CollectionBuilderComponent, CollectionDataComponent, CollectionItemBuilderComponent, CollectionsComponent } from 'pages/content/collections/_index';
import { ContentComponent } from 'pages/content/content.component';
import { PageBuilderComponent, PagesComponent } from 'pages/content/pages/_index';
import { ViewBuilderComponent, ViewsComponent } from 'pages/content/views/_index';
import { UnauthorizedComponent } from 'pages/errors/unauthorized.component';
import { bucketConfigResolver } from 'pages/form-data/bucket-config-resolver';
import { BucketDataComponent } from 'pages/form-data/bucket-data.component';
import { BucketSettingsComponent } from 'pages/form-data/bucket-settings.component';
import { BucketComponent } from 'pages/form-data/bucket.component';
import { BucketsComponent } from 'pages/form-data/buckets.component';
import { formDataComponentResolver } from 'pages/form-data/form-data-component-resolver';
import { FormDataComponent } from 'pages/form-data/form-data.component';
import { FormSummaryComponent } from 'pages/form-editor';
import { FormEditorComponent } from 'pages/form-editor/form-editor.component';
import { FormPreviewComponent } from 'pages/forms/form-preview.component';
import { FormsComponent } from 'pages/forms/forms.component';
import { HierarchyComponent } from 'pages/hierarchy/hierarchy.component';
import { LabDragListComponent, ReactiveFormsComponent } from 'pages/lab';
import { EmptyComponent } from 'pages/lab/empty.component';
import { ErrorHandlingComponent } from 'pages/lab/error-handling.component';
import { IconsComponent } from 'pages/lab/icons.component';
import { LabComponent } from 'pages/lab/lab.component';
import { ListsComponent } from 'pages/lab/lists.component';
import { StyleGuideComponent } from 'pages/lab/style-guide.component';
import { TemplateFormComponent } from 'pages/lab/template-form.component';
import { TemplateTableComponent } from 'pages/lab/template-table.component';
import { TemplateTabsComponent } from 'pages/lab/template-tabs.component';
import { LandingComponent } from 'pages/landing/landing.component';
import { MediaDetailComponent } from 'pages/media/media-detail.component';
import { MediaNewComponent } from 'pages/media/media-new.component';
import { MediaComponent } from 'pages/media/media.component';
import { DataSourcesTableComponent, DataTransactionsTableComponent, ExternalCallDetailsComponent, SettingsComponent, SettingsGeneralComponent, SettingsThemingComponent } from 'pages/project-settings';
import { ProjectOverviewComponent } from 'pages/projects/project-overview.component';
import { ProjectsComponent } from 'pages/projects/projects.component';
import { PublishComponent } from 'pages/publish/publish.component';
import { RoleDetailsComponent } from 'pages/roles/role-details.component';
import { RolesComponent } from 'pages/roles/roles.component';
import { StructureEditorComponent } from 'pages/structure';
import { AuthProvidersComponent } from 'pages/system-settings/identity';
import { AuthProviderComponent } from 'pages/system-settings/identity/auth-provider.component';
import { automaticAuthProviderCreationResolver } from 'pages/system-settings/identity/resolvers/automatic-auth-provider-creation-resolver';
import { manualAuthProviderResolver } from 'pages/system-settings/identity/resolvers/manual-auth-provider-resolver';
import { IntegrationDetailComponent } from 'pages/system-settings/integrations/integration-detail.component';
import { IntegrationFeatureComponent } from 'pages/system-settings/integrations/integration-feature.component';
import { IntegrationFeaturesComponent } from 'pages/system-settings/integrations/integration-features.component';
import { IntegrationComponent } from 'pages/system-settings/integrations/integration.component';
import { integrationResolver } from 'pages/system-settings/integrations/resolvers/integration-resolver';
import { SystemEmailSettingsComponent } from 'pages/system-settings/system-email-settings.component';
import { SystemGeneralComponent } from 'pages/system-settings/system-general.component';
import { SystemIntegrationsComponent } from 'pages/system-settings/system-integrations.component';
import { SystemMessagingComponent } from 'pages/system-settings/system-messaging.component';
import { TenantSettingsComponent as SystemSettingsComponent } from 'pages/system-settings/tenant-settings.component';
import { tableResolver } from 'pages/tables/table-resolver';
import { TableComponent, TableConfigurationComponent, TableDetailComponent, TablesComponent } from 'pages/tables/tables';
import { LoginComponent } from 'pages/user-access/login.component';
import { MFAComponent } from 'pages/user-access/mfa.component';
import { PasswordChangeComponent } from 'pages/user-access/password-change.component';
import { UserManagementComponent } from 'pages/user-management/user-management.component';
import { MyAccountComponent } from 'pages/users/my-account.component';
import { UserDetailsPageComponent } from 'pages/users/user-details-page.component';
import { UserDevicesComponent } from 'pages/users/user-devices.component';
import { UserComponent } from 'pages/users/user.component';
import { usersTableDataResolver } from 'pages/users/users-table-data-resolver';
import { UsersComponent } from 'pages/users/users.component';
import { DataForwardersComponent, WorkflowComponent, WorkflowDataForwardersFormComponent, WorkflowFormDataFormComponent, WorkflowNotificationComponent, WorkflowNotificationsComponent, WorkflowRuleFormComponent, WorkflowRulesComponent, WorkflowTimersComponent, WorkflowTimersFormComponent } from 'pages/workflows';
import { WorkflowFormDataComponent } from 'pages/workflows/workflow-form-data.component';
import { defaultProjectResolver } from 'resolvers/default-project-resolver';
import { definitionResolver } from 'resolvers/definition-resolver';
import { projectResolver } from 'resolvers/project-resolver';

export const AppRoutes: Routes = [
    // Routes
    { path: UrlSegments.Login, component: LoginComponent, data: { title: 'Login' }, canActivate: [anonymousGuard] },
    { path: UrlSegments.Sso, component: LoginComponent, data: { title: 'Login' } },
    { path: UrlSegments.FormPreview, component: FormPreviewComponent, data: { title: 'Form Preview' }, canActivate: [canActivateTenant] },
    { path: UrlSegments.PasswordChange, component: PasswordChangeComponent, data: { title: 'Password Change' }, canActivate: [passwordChangeGuard] },
    { path: UrlSegments.Mfa, component: MFAComponent, data: { title: 'Mfa' }, canActivate: [mfaGuard] },
    {
        path: UrlSegments.Root, component: TenantComponent, canActivate: [RunSeriallyGuard], data: { guards: [canActivateAccount, canActivateTenant] }, resolve: [defaultProjectResolver],
        children: [
            { path: UrlSegments.Root, component: LandingComponent, pathMatch: 'full' },
            { path: UrlSegments.Unauthorized, component: UnauthorizedComponent, data: { title: 'Unauthorized' } },
            { path: UrlSegments.MyAccount, component: MyAccountComponent, data: { title: 'My Account' }, canActivate: [userProvisioningCacheGuard], canDeactivate: [canDeactivateEditDataComponent] },
            {
                path: UrlSegments.UserManagement, component: UserManagementComponent, data: { title: 'User Management' }, canActivate: [userProvisioningCacheGuard],
                children: [
                    { path: UrlSegments.Root, redirectTo: UrlSegments.Users, pathMatch: 'full' },
                    {
                        path: UrlSegments.Users, component: UsersComponent, data: { title: 'Users' }, resolve: { tableData: usersTableDataResolver },
                        children: [
                            {
                                path: UrlSegments.IdParam, component: UserComponent, children: [
                                    { path: UrlSegments.Root, component: UserDetailsPageComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                    { path: UrlSegments.Devices, component: UserDevicesComponent, data: { title: 'Devices' } },
                                    { path: UrlSegments.Permissions, component: PermissionsComponent, data: { stretchedContainer: true, title: 'Permissions' }, canActivate: [resourceGuard] },
                                    { path: UrlSegments.InheritedPermissions, component: PermissionsComponent, data: { title: 'Inherited Permissions', inherited: true, stretchedContainer: true }, canActivate: [resourceGuard] },
                                ],
                            },
                        ],
                    },
                    {
                        path: UrlSegments.Roles, component: RolesComponent, data: { title: 'Roles' },
                        children: [
                            { path: UrlSegments.IdParam, component: RoleDetailsComponent, canActivate: [resourceGuard], canDeactivate: [canDeactivateEditDataComponent] },
                        ],
                    },
                    {
                        path: UrlSegments.UserClaims, component: ClaimTableComponent, data: { title: 'User Claims' }, children: [
                            { path: UrlSegments.IdParam, component: ClaimDetailComponent, data: { title: 'Claims' }, canDeactivate: [canDeactivateEditDataComponent] },
                        ],
                    },
                    {
                        path: UrlSegments.ApiKeys, component: APIKeysComponent, data: { title: 'API Keys' }, children: [
                            { path: UrlSegments.IdParam, component: APIKeyDetailsComponent, canActivate: [resourceGuard] },
                        ],
                    },
                    {
                        path: UrlSegments.AuditLog, component: AuditLogComponent, data: { title: 'Audit Log' },
                    },

                    {
                        path: UrlSegments.Hierarchy, component: HierarchyComponent, data: { title: 'Hierarchy' },
                    },
                ],
            },
            {
                path: UrlSegments.Assets, component: AssetsComponent, data: { title: 'Assets' }, children: [
                    { path: UrlSegments.Root, redirectTo: UrlSegments.Media, pathMatch: 'full' },
                    {
                        path: UrlSegments.Media, component: MediaComponent, data: { title: 'Media' }, children: [
                            { path: UrlSegments.NewRecord, component: MediaNewComponent, pathMatch: 'full', data: { title: 'New Media' }, canDeactivate: [canDeactivateEditDataComponent] },
                            { path: UrlSegments.IdParam, component: MediaDetailComponent, canDeactivate: [canDeactivateEditDataComponent], pathMatch: 'prefix' },
                        ],
                    },
                ],
            },
            { path: UrlSegments.Projects, component: ProjectsComponent, data: { title: 'Projects' } },
            {
                path: UrlSegments.ProjectId, component: ProjectComponent, resolve: { project: projectResolver }, canActivate: [canActivateTenant],
                children: [
                    { path: UrlSegments.Root, component: ProjectOverviewComponent },
                    { path: UrlSegments.Structure, component: StructureEditorComponent, canDeactivate: [canDeactivateEditDataComponent], data: { title: 'Structure' } },
                    {
                        path: UrlSegments.Content, component: ContentComponent, data: { title: 'Content' }, children: [
                            { path: UrlSegments.Root, pathMatch: 'full', redirectTo: UrlSegments.Collections },
                            {
                                path: UrlSegments.Collections, component: CollectionsComponent, data: { title: 'Collections' }, children: [
                                    {
                                        path: UrlSegments.IdDefinition, pathMatch: 'full', component: CollectionBuilderComponent, canDeactivate: [canDeactivateBuilder], data: { title: 'Collection' },
                                    },
                                    {
                                        path: UrlSegments.IdParam, component: CollectionDataComponent, data: { title: 'Collection' }, children: [
                                            {
                                                path: UrlSegments.IdParam, component: CollectionItemBuilderComponent, canDeactivate: [canDeactivateBuilder], data: { title: 'Collection' },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                path: UrlSegments.Views, component: ViewsComponent, data: { title: 'Views' }, children: [
                                    { path: UrlSegments.IdParam, component: ViewBuilderComponent, canDeactivate: [canDeactivateBuilder], data: { title: 'View' } },
                                ],
                            },
                            {
                                path: UrlSegments.Pages, component: PagesComponent, data: { title: 'Pages' }, children: [
                                    { path: UrlSegments.IdParam, component: PageBuilderComponent, canDeactivate: [canDeactivateBuilder], data: { title: 'Page' } },
                                ],
                            },
                        ],
                    },
                    {
                        path: UrlSegments.Forms, component: FormsComponent, data: { title: 'Forms' }, children: [
                            {
                                path: UrlSegments.IdParam, component: FormEditorComponent, data: { type: CompoundType.Form, title: 'Form' },
                                resolve: { definition: definitionResolver }, canDeactivate: [canDeactivateEditDataComponent],
                            },
                            {
                                path: UrlSegments.FormSummary, component: FormSummaryComponent, data: { type: CompoundType.Form, title: 'Form Summary' },
                                resolve: { definition: definitionResolver }, canDeactivate: [canDeactivateEditDataComponent],
                            },
                        ],
                    },
                    {
                        path: UrlSegments.FormData, component: BucketsComponent, data: { title: 'Form Data' }, children: [
                            {
                                path: UrlSegments.IdParam, component: BucketComponent, resolve: { bucketConfig: bucketConfigResolver }, data: { reuse: false }, children: [
                                    { path: UrlSegments.Root, redirectTo: UrlSegments.Settings, pathMatch: 'full' },
                                    { path: UrlSegments.Settings, component: BucketSettingsComponent, canDeactivate: [canDeactivateEditDataComponent], data: { title: 'Settings' } },
                                    {
                                        path: UrlSegments.Preview, component: BucketDataComponent, data: { title: 'Preview Form Data', preview: true, ignoreTitle: true }, children: [
                                            { path: UrlSegments.IdParam, component: FormDataComponent, resolve: { resolverData: formDataComponentResolver }, data: { title: 'Form Data', preview: true }, runGuardsAndResolvers: 'pathParamsChange' },
                                        ],
                                    },
                                    {
                                        path: UrlSegments.Live, pathMatch: 'prefix', component: BucketDataComponent, data: { title: 'Live Form Data', ignoreTitle: true }, children: [
                                            { path: UrlSegments.IdParam, component: FormDataComponent, resolve: { resolverData: formDataComponentResolver }, runGuardsAndResolvers: 'pathParamsChange', data: { title: 'Form Data' } },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: UrlSegments.Workflows, component: WorkflowComponent, children: [
                            { path: UrlSegments.Root, redirectTo: UrlSegments.WorkflowRules, pathMatch: 'full' },
                            {
                                path: UrlSegments.WorkflowRules, component: WorkflowRulesComponent, data: { title: 'Workflow Rules' }, children: [
                                    { path: UrlSegments.IdParam, component: WorkflowRuleFormComponent, data: { title: 'Rule' }, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                            {
                                path: UrlSegments.WorkflowTimers, component: WorkflowTimersComponent, data: { title: 'Workflow Timers' }, children: [
                                    { path: UrlSegments.IdParam, component: WorkflowTimersFormComponent, data: { title: 'Timer' }, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                            {
                                path: UrlSegments.WorkflowDataForwarders, component: DataForwardersComponent, data: { title: 'Workflow Data Forwarders' }, children: [
                                    { path: UrlSegments.IdParam, component: WorkflowDataForwardersFormComponent, data: { title: 'Data Forwarder' }, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                            {
                                path: UrlSegments.WorkflowNotifications, component: WorkflowNotificationsComponent, data: { title: 'Workflow Notifications' }, children: [
                                    { path: UrlSegments.IdParam, component: WorkflowNotificationComponent, data: { title: 'Notification' }, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                            {
                                path: UrlSegments.FormData, component: WorkflowFormDataComponent, data: { title: 'Workflow Form Data' }, children: [
                                    { path: UrlSegments.IdParam, component: WorkflowFormDataFormComponent, data: { title: 'Form Data' }, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                        ],
                    },
                    {
                        path: UrlSegments.Tables, component: TablesComponent, data: { title: 'Tables' }, children: [
                            {
                                path: UrlSegments.IdParam, component: TableComponent, data: { title: 'Table' }, resolve: { info: tableResolver }, children: [
                                    { path: UrlSegments.Root, component: TableConfigurationComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                    { path: UrlSegments.TableDetailPage, component: TableDetailComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                        ],
                    },
                    { path: UrlSegments.Publish, component: PublishComponent, data: { title: 'Publish' } },
                    {
                        path: UrlSegments.Settings, component: SettingsComponent, data: { title: 'Project Settings' },
                        children: [
                            { path: UrlSegments.Root, redirectTo: UrlSegments.General, pathMatch: 'full' },
                            { path: UrlSegments.General, component: SettingsGeneralComponent, canDeactivate: [canDeactivateEditDataComponent] },
                            { path: UrlSegments.Theming, component: SettingsThemingComponent, canDeactivate: [canDeactivateEditDataComponent] },
                            {
                                path: UrlSegments.DataSources, component: DataSourcesTableComponent, data: { ignoreTitle: true, title: 'Data Sources' }, children: [
                                    { path: UrlSegments.IdParam, component: ExternalCallDetailsComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                            {
                                path: UrlSegments.DataTransactions, component: DataTransactionsTableComponent, data: { ignoreTitle: true, title: 'Data Transactions' }, children: [
                                    { path: UrlSegments.IdParam, component: ExternalCallDetailsComponent, data: { isDataTransaction: true }, canDeactivate: [canDeactivateEditDataComponent] },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: UrlSegments.SystemSettings, component: SystemSettingsComponent, data: { title: 'Settings' },
                children: [
                    { path: UrlSegments.Root, redirectTo: UrlSegments.General, pathMatch: 'full' },
                    { path: UrlSegments.General, component: SystemGeneralComponent, canDeactivate: [canDeactivateEditDataComponent] },
                    {
                        path: UrlSegments.Integrations, component: SystemIntegrationsComponent, data: { title: 'Integrations', ignoreTitle: true }, children: [
                            {
                                path: UrlSegments.IdParam, component: IntegrationComponent, resolve: { integration: integrationResolver }, canDeactivate: [canDeactivateEditDataComponent], children: [
                                    { path: UrlSegments.Root, component: IntegrationDetailComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                    {
                                        path: UrlSegments.IntegrationFeatures, component: IntegrationFeaturesComponent, canDeactivate: [canDeactivateEditDataComponent],
                                        data: { ignoreTitle: true },
                                        children: [
                                            { path: UrlSegments.IdParam, component: IntegrationFeatureComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: UrlSegments.Sso, component: AuthProvidersComponent, data: { title: 'Identities', ignoreTitle: true }, canActivate: [userProvisioningCacheGuard], children: [
                            { path: UrlSegments.AutomaticRegistration, component: AuthProviderComponent, resolve: { authProviderData: automaticAuthProviderCreationResolver } },
                            {
                                path: UrlSegments.AuthProviderId, component: AuthProviderComponent, resolve: { authProviderData: manualAuthProviderResolver }, canDeactivate: [canDeactivateEditDataComponent],
                            },
                        ],
                    },
                    { path: UrlSegments.Email, component: SystemEmailSettingsComponent, canDeactivate: [canDeactivateEditDataComponent] },
                    { path: UrlSegments.Messaging, pathMatch: 'prefix', component: SystemMessagingComponent, canDeactivate: [canDeactivateEditDataComponent] },
                    {
                        path: UrlSegments.Apps, component: AppsComponent, data: { title: 'Apps', ignoreTitle: true }, children: [
                            {
                                path: UrlSegments.IdParam, component: AppDetailsComponent, data: { title: 'Apps' }, children: [
                                    { path: UrlSegments.Root, component: AppSettingsComponent, canDeactivate: [canDeactivateEditDataComponent] },
                                    {
                                        path: UrlSegments.Devices, component: AppDevicesComponent, children: [
                                            { path: UrlSegments.IdParam, component: DeviceDetailComponent, data: { title: 'Apps' } },
                                        ],
                                    },
                                    { path: UrlSegments.SendNotification, component: AppSendNotificationComponent },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: UrlSegments.Lab, component: LabComponent, data: { title: 'Lab' }, children: [
                    {
                        path: UrlSegments.Root, redirectTo: UrlSegments.StyleGuide, pathMatch: 'full',
                    },
                    {
                        path: UrlSegments.StyleGuide, component: StyleGuideComponent, data: { title: 'Style Guide' },
                    },
                    {
                        path: UrlSegments.Empty, component: EmptyComponent, data: { title: 'Empty' },
                    },
                    {
                        path: UrlSegments.Icons, component: IconsComponent, data: { title: 'Icons' },
                    },
                    {
                        path: UrlSegments.Lists, component: ListsComponent, data: { title: 'Lists' },
                    },
                    {
                        path: UrlSegments.DragList, component: LabDragListComponent, data: { title: 'Drag List' },
                    },
                    {
                        path: UrlSegments.ReactiveForms, component: ReactiveFormsComponent, data: { title: 'Reactive Forms' },
                    },
                    {
                        path: UrlSegments.TemplateForm, component: TemplateFormComponent, data: { title: 'Form' },
                    },
                    {
                        path: UrlSegments.TemplateTable, component: TemplateTableComponent, data: { title: 'Table' },
                    },
                    {
                        path: UrlSegments.TemplateTabs, component: TemplateTabsComponent, data: { title: 'Tabs' }, children: [
                            { path: UrlSegments.Root, pathMatch: 'prefix', component: TemplateFormComponent },
                            { path: UrlSegments.Table, component: TemplateTableComponent },
                        ],
                    },
                    {
                        path: UrlSegments.ErrorHandling, component: ErrorHandlingComponent, data: { title: 'Error Handling' },
                    },
                ],
            },
        ],
    },
    { path: UrlSegments.Wildcard, redirectTo: UrlSegments.Root, pathMatch: 'full' },
];
