import { SortStatus, TableDataSource } from '@unifii/library/common';
import { ReplaySubject } from 'rxjs';

import { PublishItem } from 'services/project-publisher';

export class PublishItemsDatasource extends TableDataSource<PublishItem> {

    filtered = false;
    sorted = false;
    loaded = new ReplaySubject(1);

    constructor(
        private items: PublishItem[],
        private sort?: SortStatus,
    ) {
        super();
    }

    async load() {
        // TODO value are not displayed without a timeout, need library fix
        await new Promise((res) => setTimeout(() => res(''), 100));

        const sortName = this.sort?.name as keyof PublishItem | undefined;

        if (!sortName || !['consoleName', 'publishableType', 'lastModifiedAt', 'lastModifiedBy'].includes(sortName)) {
            this.stream.next({ data: this.items });
            this.loaded.next(undefined);

            return;
        }

        const sortedItems = this.items.sort((a, b) => {
            const valueA = this.getAttributeValue(a, sortName) ?? '';
            const valueB = this.getAttributeValue(b, sortName) ?? '';
            const sortResult = valueA > valueB ? -1 : 1;

            return this.sort?.direction === 'asc' ? sortResult : sortResult * -1;
        });

        this.stream.next({ data: sortedItems });
        this.loaded.next(undefined);
    }

    private getAttributeValue(item: PublishItem, attribute: keyof PublishItem): string | undefined {
        if (['publishableType', 'lastModifiedAt'].includes(attribute)) {
            return item[attribute] as string | undefined;
        }
        if (attribute === 'lastModifiedBy') {
            return item.lastModifiedBy?.username;
        }
        if (attribute === 'consoleName') {
            return item.consoleName ?? item.id;
        }

        return undefined;
    }

}
