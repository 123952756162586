import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@unifii/components';
import { DisplayModule } from '@unifii/library/smart-forms/display';
import { InputModule } from '@unifii/library/smart-forms/input';
import { UserProvisioningModule } from '@unifii/user-provisioning';

import { COMPONENTS } from 'components';
import { BuilderFieldsHeaderComponent } from 'components/compound-builder/builder-fields-header.component';
import { FieldReferencePickerComponent } from 'components/compound-builder/field-reference-picker.component';
import { DragHandleDirective } from 'components/dragdrop/drag-handle.directive';
import { DragItemDirective } from 'components/dragdrop/drag-item.directive';
import { DragListComponent } from 'components/dragdrop/drag-list.component';
import { ErrorComponent } from 'components/error/error.component';
import { ExpanderControlsComponent } from 'components/expander/expander-controls.component';
import { DATA_SOURCE_EDITOR_COMPONENTS } from 'components/field-builder/data-source-editor';
import { DataSourceMappingsReorderComponent } from 'components/field-builder/data-source-editor/data-source-mappings-reorder.component';
import { FieldBuilderComponent } from 'components/field-builder/field-builder.component';
import { FieldDisplayComponent } from 'components/field-builder/field-display.component';
import { FieldNestedComponent } from 'components/field-builder/field-nested.component';
import { FieldOptionComponent } from 'components/field-builder/field-option.component';
import { FieldOptionsComponent } from 'components/field-builder/field-options.component';
import { FieldSettingsComponent } from 'components/field-builder/field-settings.component';
import { FieldValidatorsComponent } from 'components/field-builder/field-validators.component';
import { FieldVisibilityComponent } from 'components/field-builder/field-visibility.component';
import { KeySelectDirective } from 'components/field-builder/key-select.directive';
import { StepComponent } from 'components/field-builder/step.component';
import { FILTER_EDITOR_COMPONENTS } from 'components/filter-editor';
import { FORM, UcAssetComponent, UcColorComponent, UcMarkdownComponent, UcMarkdownTokenizerComponent } from 'components/form/_index';
import { UcMarkdownWrapperComponent } from 'components/form/uc-markdown-wrapper.component';
import { ImageCropperComponent } from 'components/image-cropper/image-cropper.component';
import { PERMISSIONS_COMPONENTS } from 'components/permissions';
import { InputMaskDirective } from 'directives/input-mask.directive';
import { MasterDetailDirective } from 'directives/master-detail.directive';
import { RestrictDirective } from 'directives/restrict.directive';
import { TooltipDirective } from 'directives/tooltip.directive';
import { MenuItemComponent } from 'navigation/menu-item.component';
import { NavContextMenuComponent } from 'navigation/nav-context-menu.component';
import { ProjectNavComponent } from 'navigation/project-nav.component';
import { ProjectComponent } from 'navigation/project.component';
import { TenantComponent } from 'navigation/tenant.component';
import { APIKeyDetailsComponent } from 'pages/api-keys/api-key-details.component';
import { APIKeysComponent } from 'pages/api-keys/api-keys.component';
import { NewApiKeyModalComponent } from 'pages/api-keys/new-api-key-modal.component';
import { AppDetailsComponent } from 'pages/apps/app-details.component';
import { AppDevicesComponent } from 'pages/apps/app-devices.component';
import { AppSendNotificationComponent } from 'pages/apps/app-send-notification.component';
import { AppSettingsComponent } from 'pages/apps/app-settings.component';
import { AppsComponent } from 'pages/apps/apps.component';
import { DeviceDetailComponent } from 'pages/apps/device-detail.component';
import { SelectDevicesComponent } from 'pages/apps/select-devices.component';
import { UsersQueryBuilderComponent } from 'pages/apps/users-query-builder.component';
import { AssetsComponent } from 'pages/assets/assets.component';
import { AuditLogComponent } from 'pages/audit-log/audit-log.component';
import { ClaimDetailComponent, ClaimTableComponent } from 'pages/claim-config';
import { COLLECTION_COMPONENTS } from 'pages/content/collections/_index';
import { ContentComponent } from 'pages/content/content.component';
import { PAGE_COMPONENTS } from 'pages/content/pages/_index';
import { PageBuilderComponent } from 'pages/content/pages/page-builder.component';
import { PagesComponent } from 'pages/content/pages/pages.component';
import { VIEW_COMPONENTS } from 'pages/content/views/_index';
import { UnauthorizedComponent } from 'pages/errors/unauthorized.component';
import { FORM_DATA_COMPONENTS } from 'pages/form-data';
import { FORM_EDITOR_COMPONENTS } from 'pages/form-editor';
import { FieldMapperComponent } from 'pages/form-editor/field-configuration/field-mapper.component';
import { FormDataDisplayModal } from 'pages/forms/form-data-display-modal.component';
import { FormPreviewComponent } from 'pages/forms/form-preview.component';
import { FormsComponent } from 'pages/forms/forms.component';
import { HIERARCHY_COMPONENTS } from 'pages/hierarchy';
import { LAB_COMPONENTS } from 'pages/lab';
import { LandingComponent } from 'pages/landing/landing.component';
import { FileConflictModalComponent } from 'pages/media/file-conflict-modal.component';
import { MediaDetailComponent } from 'pages/media/media-detail.component';
import { MediaNewComponent } from 'pages/media/media-new.component';
import { MediaComponent } from 'pages/media/media.component';
import { PROJECT_SETTINGS_COMPONENTS } from 'pages/project-settings';
import { NewProjectModalComponent } from 'pages/projects/new-project-modal.component';
import { ProjectOverviewComponent } from 'pages/projects/project-overview.component';
import { ProjectsComponent } from 'pages/projects/projects.component';
import { PublishComponent } from 'pages/publish/publish.component';
import { RoleDetailsComponent } from 'pages/roles/role-details.component';
import { RoleModalComponent } from 'pages/roles/role-modal.component';
import { RolesComponent } from 'pages/roles/roles.component';
import { STRUCTURE_COMPONENTS } from 'pages/structure';
import { IDENTITY_COMPONENTS } from 'pages/system-settings/identity';
import { INTEGRATION_COMPONENTS } from 'pages/system-settings/integrations';
import { SecretFieldComponent } from 'pages/system-settings/secret-input-field.component';
import { SecretInputComponent } from 'pages/system-settings/secret-input.component';
import { SystemEmailSettingsComponent } from 'pages/system-settings/system-email-settings.component';
import { SystemEmailPreviewModalComponent } from 'pages/system-settings/system-email-template-preview-modal.component';
import { SystemEmailTemplatesComponent } from 'pages/system-settings/system-email-templates.component';
import { SystemGeneralComponent } from 'pages/system-settings/system-general.component';
import { SystemIntegrationsComponent } from 'pages/system-settings/system-integrations.component';
import { SystemMessagingComponent } from 'pages/system-settings/system-messaging.component';
import { SystemSmtpTestModalComponent } from 'pages/system-settings/system-smtp-test-modal.component';
import { TenantSettingsComponent } from 'pages/system-settings/tenant-settings.component';
import { TABLES_COMPONENTS } from 'pages/tables/tables';
import { DragTestComponent } from 'pages/testing/drag-test.component';
import { ImageCropperTestComponent } from 'pages/testing/image-cropper-test.component';
import { ContactInfoModalComponent } from 'pages/user-access/contact-info-modal.component';
import { LoginComponent } from 'pages/user-access/login.component';
import { MFAComponent } from 'pages/user-access/mfa.component';
import { PasswordChangeComponent } from 'pages/user-access/password-change.component';
import { UserAccessBoxComponent } from 'pages/user-access/user-access-box.component';
import { UserManagementComponent } from 'pages/user-management/user-management.component';
import { USER_COMPONENTS } from 'pages/users';
import { UserDetailsPageComponent } from 'pages/users/user-details-page.component';
import { UserDevicesComponent } from 'pages/users/user-devices.component';
import { UserComponent } from 'pages/users/user.component';
import { UsersComponent } from 'pages/users/users.component';
import { WORKFLOW_COMPONENTS } from 'pages/workflows';
import { PIPES } from 'pipes';

@NgModule({
    imports: [
        RouterModule, InputModule, DisplayModule, ComponentsModule, UserProvisioningModule,
    ],
    declarations: [
        InputMaskDirective,
        BuilderFieldsHeaderComponent,
        FieldReferencePickerComponent,
        ClaimTableComponent, ClaimDetailComponent,
        DragHandleDirective, DragItemDirective,
        DragListComponent, FieldBuilderComponent, FieldDisplayComponent, FieldNestedComponent,
        FieldOptionsComponent, FieldOptionComponent, FieldSettingsComponent, FieldValidatorsComponent,
        FieldVisibilityComponent, KeySelectDirective, StepComponent, ImageCropperComponent, ExpanderControlsComponent,
        ProjectComponent, ProjectNavComponent, TenantComponent, ProjectComponent, ProjectNavComponent, TenantComponent,
        FormPreviewComponent, FormDataDisplayModal, FormsComponent, UserAccessBoxComponent, LoginComponent, MFAComponent, PasswordChangeComponent, ContactInfoModalComponent, UnauthorizedComponent, FileConflictModalComponent,
        MediaComponent, MediaDetailComponent, MediaNewComponent,
        AppsComponent, AppDetailsComponent, AppSettingsComponent, AppDevicesComponent, AppSendNotificationComponent, DeviceDetailComponent, SelectDevicesComponent,
        PageBuilderComponent, PagesComponent, ProjectsComponent, NewProjectModalComponent, ProjectOverviewComponent, PublishComponent,
        DragTestComponent, ImageCropperTestComponent, LandingComponent,
        UsersQueryBuilderComponent,
        UserManagementComponent, UsersComponent, UserComponent, UserDetailsPageComponent, UserDevicesComponent, RolesComponent, RoleModalComponent, RoleDetailsComponent,
        APIKeysComponent, NewApiKeyModalComponent, APIKeyDetailsComponent, ErrorComponent, MenuItemComponent, AuditLogComponent,
        MasterDetailDirective, RestrictDirective, TooltipDirective, TenantSettingsComponent, FieldMapperComponent,
        SystemGeneralComponent, SystemIntegrationsComponent, SecretInputComponent,
        SecretFieldComponent, DataSourceMappingsReorderComponent, SystemEmailSettingsComponent, SystemEmailTemplatesComponent,
        SystemMessagingComponent, SystemSmtpTestModalComponent,
        SystemEmailPreviewModalComponent, ContentComponent, NavContextMenuComponent, AssetsComponent,
        PIPES, INTEGRATION_COMPONENTS, STRUCTURE_COMPONENTS, LAB_COMPONENTS, FORM_EDITOR_COMPONENTS, FORM_DATA_COMPONENTS,
        DATA_SOURCE_EDITOR_COMPONENTS, FILTER_EDITOR_COMPONENTS, VIEW_COMPONENTS, COLLECTION_COMPONENTS,
        PAGE_COMPONENTS, FORM, TABLES_COMPONENTS, WORKFLOW_COMPONENTS, COMPONENTS, HIERARCHY_COMPONENTS, IDENTITY_COMPONENTS,
        PERMISSIONS_COMPONENTS, USER_COMPONENTS, PROJECT_SETTINGS_COMPONENTS,
    ],
    exports: [
        InputModule, DisplayModule, ComponentsModule, UserProvisioningModule,
        MasterDetailDirective, InputMaskDirective,
        TooltipDirective, BuilderFieldsHeaderComponent,
        FieldReferencePickerComponent,
        ClaimTableComponent, ClaimDetailComponent,
        DragHandleDirective, DragItemDirective,
        DragListComponent, FieldBuilderComponent, FieldDisplayComponent, FieldNestedComponent,
        FieldOptionsComponent, FieldOptionComponent, FieldSettingsComponent, FieldValidatorsComponent,
        FieldVisibilityComponent, KeySelectDirective, StepComponent, ImageCropperComponent, ExpanderControlsComponent,
        ProjectComponent, ProjectNavComponent, TenantComponent,
        FormPreviewComponent, FormDataDisplayModal, FormsComponent, UserAccessBoxComponent, ContactInfoModalComponent, FileConflictModalComponent, LoginComponent, PasswordChangeComponent, MFAComponent,
        UnauthorizedComponent, MediaComponent, MediaDetailComponent, MediaNewComponent,
        AppsComponent, AppDetailsComponent, AppSettingsComponent, AppDevicesComponent, AppSendNotificationComponent, DeviceDetailComponent, SelectDevicesComponent,
        PageBuilderComponent, PagesComponent, ProjectsComponent, NewProjectModalComponent, ProjectOverviewComponent, ProjectOverviewComponent, PublishComponent,
        DragTestComponent, ImageCropperTestComponent, LandingComponent, UsersQueryBuilderComponent,
        UserManagementComponent, UsersComponent, UserComponent, UserDetailsPageComponent, UserDevicesComponent, RolesComponent, RoleModalComponent, RoleDetailsComponent,
        APIKeysComponent, NewApiKeyModalComponent, APIKeyDetailsComponent, ErrorComponent, TenantSettingsComponent, FieldMapperComponent,
        SystemGeneralComponent, SystemIntegrationsComponent, DataSourceMappingsReorderComponent,
        UcMarkdownComponent, UcMarkdownWrapperComponent, UcMarkdownTokenizerComponent, UcColorComponent, UcAssetComponent, SystemMessagingComponent, SystemEmailSettingsComponent, SystemEmailTemplatesComponent, SystemSmtpTestModalComponent,
        UserProvisioningModule, SystemEmailPreviewModalComponent, ContentComponent, NavContextMenuComponent, AssetsComponent,
        PIPES, INTEGRATION_COMPONENTS, STRUCTURE_COMPONENTS, LAB_COMPONENTS, FORM_EDITOR_COMPONENTS, FORM_DATA_COMPONENTS,
        DATA_SOURCE_EDITOR_COMPONENTS, FILTER_EDITOR_COMPONENTS, VIEW_COMPONENTS, COLLECTION_COMPONENTS,
        PAGE_COMPONENTS, TABLES_COMPONENTS, WORKFLOW_COMPONENTS, COMPONENTS, HIERARCHY_COMPONENTS, IDENTITY_COMPONENTS,
        PERMISSIONS_COMPONENTS, USER_COMPONENTS, PROJECT_SETTINGS_COMPONENTS,
    ],
    providers: [PIPES],
})
export class ConsoleModule { }
